/*global Modernizr:false, console:false*/
// import "es6-promise/auto";
import { setupJQueryAjax } from "./tools/network";

(function () {
    "use strict";

    $(document).ready(function () {
        setupJQueryAjax();

        window.setTimeout(function () {
            if (typeof $().tooltip === "function") {
                $('[data-toggle="tooltip"]').tooltip({html: true});
            }
        }, 500);
    });

}());

// Main Izele namespace
(function (izele, $, undefined) {
    izele.debug = false;
    izele.currentTab = null;
    izele.messages = [];

    izele.vueApps = {};

    izele.pageData = {
        type: undefined
    };

    izele.organisationData = {
        id: null,
        name: null,
        acceptsVisitors: false,
        initNumberFavourites: 0,
        initNumberVisitors: 0,
        centroid: null,
        envelope: null,
        boundary: null,

        relationships: [],
        umbrellaRelationships: {
            is_umbrella_parent: false,
            is_umbrella_child: false,
            umbrella_parent: null,
            umbrella_parent_link: undefined,
            umbrella_children: []
        }

    };
    izele.userData = {
        loggedIn: false,
        id: null,
        isAdminForThisPage: false,
        initFavourited: false,
        initVisited: false,
        isAdminFor: [],
        fullName: null
    };
    izele.urls = {};
    izele.notifications = {};
    izele.maxNotificationsInMenu = 6;

    izele.acceptedImageTypes = ['.jpg', '.jpeg', 'image/jpeg', '.png', 'image/png', '.gif', 'image/gif'];  // '.svg', 'image/svg+xml', '.tif', '.tiff', 'image/tiff'
    izele.maximumImageSizeMB = 10;
    // izele.maximumLogoSizeMB = 0.5;
    izele.maximumFileSize = 10;
    izele.imageMaxDimension = 2048;

}(window.izele = window.izele || {}, jQuery));
