// Mapping namespace
(izele.mapping = function (mapping, $, L, undefined) {
    mapping.styles = {
        markers: {
            mainOrgMarker: "",
            surroundingOrgMarker: ""
        },
        polygons: {
            default: {
                color: "#666699", "weight": 2, fillOpacity: 0.25
            },
            mainOrgPoly: {
                color: "#339966", "weight": 2, fillOpacity: 0.25
            }
        }
    };

    mapping.map = undefined;
    mapping.attributionText = gettext('Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors') + ', <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>';

    mapping.initialiseMap = function () {
        var mapContainer = $("#main-map");

        if (izele.organisationData.centroid === null && izele.organisationData.boundary === null && izele.organisationData.envelope === null) {
            mapContainer.children().html(gettext("No map data to display"));

        } else {
            if (mapping.map === undefined) {
                L.Icon.Default.imagePath = '/static/img/mapping/';
                //L.mapbox.accessToken = 'pk.eyJ1IjoibWFyaW5lbWFwcGluZyIsImEiOiJzM0Zxa2NJIn0.CsE3SQa_5YzTxA50eDsW8w';

                // clear out the loading message
                mapContainer.html("");

                mapping.map = L.map('main-map', {
                    crs: L.CRS.EPSG900913,
                    zoomControl: false,
                    layers: [mapping.mapLayers.backgroundMapping],
                    fullscreenControl: true
                });
                L.control.scale().addTo(mapping.map);
                mapping.map.addControl(L.control.zoom({position: 'topright'}));

            }
            var mapPositionLat = 0, mapPositionLng = 0;
            if (izele.organisationData.centroid !== null) {
                mapPositionLat = izele.organisationData.centroid.coordinates[1];
                mapPositionLng = izele.organisationData.centroid.coordinates[0];
            }

            mapping.setMapBoundsToPolyOrPoint(mapPositionLat, mapPositionLng, izele.organisationData.boundary);
        }
    };

    mapping.createIconFromImage = function (image, maxWidth, maxHeight, anchor) {
        // todo: refactor getting new size into generic function in tools?
        maxWidth = typeof maxWidth!== 'undefined' ? maxWidth: 50;
        maxHeight = typeof maxHeight!== 'undefined' ? maxHeight: 50;

        var tempImageElement = $("<img src='" + image + "'>");
        var imageWidth = tempImageElement[0].width;
        var imageHeight = tempImageElement[0].height;

        var ratio = 0, newWidth = imageWidth, newHeight = imageHeight;

        if (imageWidth > maxWidth) {
            ratio = maxWidth / imageWidth;
            newWidth = maxWidth;
            newHeight = imageHeight * ratio;
        }

        if (newHeight > maxHeight) {
            ratio = maxHeight / newHeight;
            newWidth = newWidth * ratio;
            newHeight = maxHeight;
        }

        var iconSettings = {
            iconUrl: image,
            iconSize: [newWidth, newHeight]
        };
        if (anchor !== undefined) {
            iconSettings['iconAnchor'] = anchor;
        }

        return L.icon(iconSettings)
    };

    mapping.createMarker = function (geodata, metadata, icon) {
        return L.geoJson(geodata, {
            onEachFeature: function (feature, layer) {
                if (metadata !== undefined) {
                    layer.bindPopup(mapping.formatOrganisationPopup(metadata));
                }

            },
            pointToLayer: function(feature, latlng) {
                if (icon !== undefined) {
                    return L.marker(latlng, {icon: icon})
                } else {
                    return L.marker(latlng)
                }
            }
        });
    };

    mapping.createPolygonLayer = function (geodata, metadata, styles) {
        return L.geoJson(geodata, {
            onEachFeature: function (feature, layer) {
                layer.bindPopup(mapping.formatOrganisationPopup(metadata));
            },
            style: function (feature) {
                if (styles === undefined) {
                    return mapping.styles.polygons.default;
                } else {
                    return styles;
                }
            }
        });
    };

    mapping.formatOrganisationPopup = function (orgData) {
        return "" +
            "<div id='map-popup-content'>" +
                "<table>" +
                    "<tr>" +
                        "<td class='map-popup-left'>" +
                            "<a href='/" + orgData.id + "/" + orgData.organisation_slug + "/'>" +
                                "<img style='max-width: 50px' src='" + orgData.get_organisation_logo + "'>" +
                            "</a>" +
                        "</td>" +
                        "<td>" +
                            "<a href='/" + orgData.id + "/" + orgData.organisation_slug + "/'>" + orgData.organisation_name + "</a>" +
                        "</td>" +
                    "</tr>" +
                "</table>" +
            "</div>";
    };

    // todo: delete once updateMainMapAfterOrgLocationChange moved
    mapping.setMapBoundsToPolyOrPoint = function (pointLocationLat, pointLocationLng, polyLocation, zoom) {
        // if we have a bounds object, fit the map to that, otherwise just use centroid coordinates
        zoom = typeof zoom !== 'undefined' ? zoom: 10;

        if (polyLocation !== null && polyLocation !== undefined) {
            mapping.map.fitBounds(L.geoJson(polyLocation).getBounds());
        } else {
            mapping.map.setView([pointLocationLat, pointLocationLng], zoom);
        }
    };

    mapping.setMapBounds = function (map, envelope, boundary, centroid, zoom) {
        // priorities in order of parameters
        zoom = typeof zoom !== 'undefined' ? zoom: 10;

        if (envelope !== null) {
            map.fitBounds(L.geoJson(envelope).getBounds());

        } else if (boundary !== null) {
            map.fitBounds(L.geoJson(boundary).getBounds());

        } else if (centroid !== null) {
            map.setView([centroid.coordinates[1], centroid.coordinates[0]], zoom);

        } else {
            map.setView([0, 0], 2);

        }

    };

    // todo: move
    mapping.updateMainMapAfterOrgLocationChange = function (data) {
        var dataType = data.type;

        if (dataType === 'Point') {
            izele.organisationData.centroid = data;

        } else if (dataType === 'MultiPolygon') {
            izele.organisationData.boundary = data;
        }

        // currently updates all the org layers, could be made more efficient ?
        if (izele.mapping.map === undefined) {
            izele.mapping.initialiseMap();

        } else {
            izele.mapping.map.removeLayer(izele.mapping.mapLayers.organisation.marker);
            izele.mapping.map.removeLayer(izele.mapping.mapLayers.organisation.polygon);
            izele.mapping.mapLayers.organisation = {polygon: L.featureGroup(), marker: L.featureGroup(), created: false};

            if (izele.organisationData.boundary !== null) {
                izele.mapping.setMapBoundsToPolyOrPoint(0, 0, izele.organisationData.boundary);
            } else if (izele.organisationData.envelope !== null) {
                izele.mapping.setMapBoundsToPolyOrPoint(0, 0, izele.organisationData.envelope);
            } else if (izele.organisationData.centroid !== null) {
                izele.mapping.map.setView(new L.LatLng(izele.organisationData.centroid.coordinates[1], izele.organisationData.centroid.coordinates[0]), 10);
            }
        }

        // update the modal description. todo: move this into vue? and above?
        var organisationLocationType = 'unknown';

        if (izele.organisationData.boundary !== null) {
            organisationLocationType = 'boundary';
        } else if (izele.organisationData.envelope !== null) {
            organisationLocationType = 'rough rectangle';
        } else if (izele.organisationData.centroid !== null) {
            organisationLocationType = 'point location';
        }

        $("#organisation-location-type-text").html(gettext('represented as a') + '<i>' + organisationLocationType + '</i>');

    }
})(izele.mapping = izele.mapping || {}, jQuery, L);


// Map layers
(izele.mapping.mapLayers = function(mapLayers, $, L, undefined) {
    mapLayers.backgroundMapping = L.tileLayer(
        'https://{s}.tiles.mapbox.com/v3/marinemapping.def5fe71/{z}/{x}/{y}.png', {attribution: izele.mapping.attributionText});

    // todo: these 2 moved, delete?
    mapLayers.organisation = {polygon: L.featureGroup(), marker: L.featureGroup(), created: false};
    mapLayers.surroundingOrganisations = {polygons: L.featureGroup(), markers: L.featureGroup(), created: false};

})(izele.mapping.mapLayers = izele.mapping.mapLayers || {}, jQuery, L);